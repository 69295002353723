// @ts-nocheck
import { Box, Button, Divider, Link, Typography, styled } from '@mui/material';
import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextReactComponents,
} from '@portabletext/react';
import type { PortableTextBlockStyle } from '@portabletext/types';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import Image from 'src/components/Image';
import Routes from 'src/routes';
import { imageProps } from '../image';
import LinkedPosts from './LinkedPosts';
import SanityImage from 'src/components/media/SanityImage';
import { dataset, projectId } from 'src/lib/sanity.client';

const ReactPlayer = dynamic(() => import('react-player/vimeo'), {
  ssr: false,
});

const StyledReactPlayer = styled(ReactPlayer)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
}));

const StyledNextImage = styled(Image)(() => ({
  borderRadius: 16,
}));

const PostBody = ({ content }: any) => (
  <PortableText
    value={content}
    // imageOptions={{ q: 60, width: 700 }}
    components={components}
    // projectId={process.env.SANITY_STUDIO_API_PROJECT_ID}
    // dataset={process.env.SANITY_STUDIO_API_DATASET}
  />
);

const MARGIN = {
  marginTop: 3,
  marginBottom: 2,
};

const overrides:
  | Record<PortableTextBlockStyle, PortableTextBlockComponent | undefined>
  | PortableTextBlockComponent = {
  h1: ({ children }) => (
    <Typography variant="h2" sx={{ ...MARGIN }}>
      {children}
    </Typography>
  ),
  h2: (props) => <Typography variant="h3" sx={{ ...MARGIN }} {...props} />,
  h3: (props) => <Typography variant="h4" sx={{ ...MARGIN }} {...props} />,
  normal: (props) => <Typography variant="body1" sx={{ ...MARGIN }} {...props} />,
};

const components: Partial<PortableTextReactComponents> = {
  block: overrides,
  types: {
    video: ({ value }) => {
      const { url } = value;

      return <ResponsivePlayer url={url} />;
    },
    horizontalRule: () => {
      return <Divider />;
    },
    linkedPosts: ({ value }) => {
      const { title, articles, offers, projects } = value;

      return <LinkedPosts title={title} articles={articles} projects={projects} offers={offers} />;
    },
    image: (props) => {
      const { width, height } = imageProps(props.value);
      const image = props.value;
      const ratio = width / height;
      return (
        // <Box
        //   position="relative"
        //   width={props.value.width | 1}
        //   height={
        //     props.value.height
        //       ? props.value.height
        //       : props.value.width
        //       ? props.value.width / ratio
        //       : 320
        //   }
        //   maxWidth="100%"
        // >
        //   <StyledNextImage
        //     alt={props.value.alt || ''}
        //     style={{ objectFit: dimensionsSet ? 'contain' : 'cover' }}
        //     sizes={{ xs: 12, md: 7 }}
        //     src={props.value}
        //   />
        // </Box>
        <Box
          sx={{
            position: 'relative',
            width: 1,
            aspectRatio: ratio,
            maxHeight: 700,

            '& img': {
              borderRadius: 2,
              width: 'auto!important',
              margin: '0 auto',
            },
          }}
        >
          <SanityImage
            src={image?.asset?._ref}
            alt={image?.altText}
            crop={image?.crop}
            hotspot={image?.hotspot}
            dataset={dataset}
            projectId={projectId}
            layout="fill"
            objectFit="contain"
            sizes="100vw"
          />
        </Box>
      );
    },
  },
  marks: {
    link: ({ value, children }) => {
      const { 0: title } = children;

      return <CustomLink link={{ ...value, title }} />;
    },
  },
};

const ResponsivePlayer = ({ url }) => {
  return (
    <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
      <StyledReactPlayer
        width="100%"
        height="100%"
        config={{
          vimeo: {
            playerOptions: { byline: false, portrait: false, title: false },
          },
        }}
        controls
        url={url}
      />
    </Box>
  );
};

const CustomLink = ({ link, children, ...rest }) => {
  if (link.linkType == 'external') {
    return (
      <a
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        {link.isButton ? (
          <Button color={link.styles?.style} variant="contained">
            {link.title || children}
          </Button>
        ) : (
          <>{link.title || children}</>
        )}
      </a>
    );
  } else {
    return (
      <NextLink
        href={Routes[link.page.type]('[slug]')}
        as={Routes[link.page.type](link.page.slug)}
        passHref
        legacyBehavior
      >
        {link.isButton ? (
          <Button color={link.styles?.style} variant="contained">
            {link.title || children}
          </Button>
        ) : (
          <Link color="#00f">{link.title || children}</Link>
        )}
      </NextLink>
    );
  }
};

export default PostBody;
