/* eslint-disable react-hooks/rules-of-hooks */
import { ImageUrlBuilder, useNextSanityImage } from 'next-sanity-image';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { getClient } from 'src/lib/sanity.client';

const sanity = getClient();

const myCustomImageBuilder = (
  imageUrlBuilder: ImageUrlBuilder,
  options: { width: any; originalImageDimensions: { width: number } }
) => {
  return imageUrlBuilder
    .width(Math.min(options.width || Math.min(options.originalImageDimensions.width, 900), 1920))
    .auto('format')
    .quality(80);
};

const imageBuilder = imageUrlBuilder(sanity);

const imageUrlFor = (source: SanityImageSource, width?: number) =>
  imageBuilder
    .width(width ? width : 900)
    .image(source)
    .auto('format');

export const imageProps = (source: SanityImageSource, omitSize = false) =>
  omitSize
    ? (({ src, loader }) => ({ src, loader }))(
        useNextSanityImage(sanity, source, { imageBuilder: myCustomImageBuilder })
      )
    : useNextSanityImage(sanity, source);

//----------------------------------------------------------------------------------------------

const ImageBuilder = (
  imageUrlBuilder: ImageUrlBuilder,
  options: { width: any; originalImageDimensions: { width: number } }
) => {
  return imageUrlBuilder.width(options.width).auto('format').quality(70);
};

export const newImageProps = (source: SanityImageSource) =>
  (({ src, loader }) => ({ src, loader }))(
    useNextSanityImage(sanity, source, { imageBuilder: ImageBuilder })
  );

export default imageUrlFor;
