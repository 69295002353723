import arrowRight from '@iconify/icons-carbon/chevron-right';
import { Box, Paper, Stack, Typography } from '@mui/material';
import NextLink from 'next/link';
import { memo, useEffect, useState } from 'react';
import { Iconify, TextMaxLine } from 'src/components';
import Image from 'src/components/Image';
import { getClient } from 'src/lib/sanity.client';
import Routes from 'src/routes';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  articles?: object[];
  projects?: object[];
  offers?: object[];
};

const LinkedPosts: React.FC<Props> = (props) => {
  const { title, articles: articleRefs, projects: projectRefs, offers: offerRefs } = props;

  const [fetchedArticles, setFetchedArticles] = useState<any>();
  const [fetchedProjects, setFetchedProjects] = useState<any>();
  const [fetchedOffers, setFetchedOffers] = useState<any>();

  useEffect(() => {
    if (articleRefs) {
      const query = `*[_type == "article"] {
          _id,
          title,
          header,
          description,
          'slug': slug.current,
        }`;

      getClient()
        .fetch(query)
        .then((articles) => {
          setFetchedArticles(articles);
        });
    }

    if (projectRefs) {
      const query = `*[_type == "project"] {
          _id,
          title,
          header,
          description,
          'slug': slug.current,
        }`;

      getClient()
        .fetch(query)
        .then((projects) => {
          setFetchedProjects(projects);
        });
    }

    if (offerRefs) {
      const query = `*[_type == "offer"] {
          _id,
          title,
          image,
          description,
          'slug': slug.current,
        }`;

      getClient()
        .fetch(query)
        .then((offers) => {
          setFetchedOffers(offers);
        });
    }
  }, [title, articleRefs, projectRefs, offerRefs]);

  const articles =
    (articleRefs &&
      fetchedArticles &&
      fetchedArticles.filter((article: any) =>
        articleRefs.map((ref: any) => ref._ref).includes(article._id)
      )) ||
    [];

  const projects =
    (projectRefs &&
      fetchedProjects &&
      fetchedProjects.filter((article: any) =>
        projectRefs.map((ref: any) => ref._ref).includes(article._id)
      )) ||
    [];

  const offers =
    (offerRefs &&
      fetchedOffers &&
      fetchedOffers.filter((article: any) =>
        offerRefs.map((ref: any) => ref._ref).includes(article._id)
      )) ||
    [];

  return (
    <Paper
      sx={{
        my: 5,
        mx: -1.5,
        p: 3,
        pt: 2,
        border: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Stack direction="column" spacing={2.5}>
        <Typography variant="h5" fontWeight={800}>
          {title}
        </Typography>
        <Stack direction="column" spacing={2.5}>
          {articles.map((article: any) => (
            <Post
              header={article.header}
              title={article.title}
              description={article.description}
              key={article._id}
              path={Routes.blog}
              slug={article.slug}
            />
          ))}
          {projects.map((article: any) => (
            <Post
              header={article.header}
              title={article.title}
              description={article.description}
              key={article._id}
              path={Routes.projects}
              slug={article.slug}
            />
          ))}
          {offers.map((article: any) => (
            <Post
              header={article.image}
              title={article.title}
              description={article.description}
              key={article._id}
              path={Routes.offers}
              slug={article.slug}
            />
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
};

type PostProps = {
  title: string;
  header: any;
  description: string;
  path: string;
  slug: string;
};

const Post: React.FC<PostProps> = ({ title, header, description, path, slug }) => {
  const as = `${path}/${slug}`;
  const href = `${path}/[slug]`;

  return (
    <>
      <Stack
        spacing={3}
        direction="row"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        sx={{ width: 1 }}
      >
        <NextLink passHref as={as} href={href} legacyBehavior>
          <Box
            component="a"
            sx={{
              overflow: 'hidden',
              position: 'relative',
              width: 100,
              height: 100,
              flexShrink: 0,
              borderRadius: 1.5,
            }}
          >
            <Image src={header} alt={title} sizes={{ xs: 2 }} style={{ objectFit: 'cover' }} />
          </Box>
        </NextLink>
        <Box>
          <NextLink passHref as={as} href={href} legacyBehavior>
            <TextMaxLine
              variant={'subtitle1'}
              asLink
              sx={{ alignItems: 'center', display: 'flex' }}
            >
              {title}
              <Iconify ml={0.5} icon={arrowRight} />
            </TextMaxLine>
          </NextLink>
          <NextLink passHref as={as} href={href} legacyBehavior>
            <TextMaxLine
              asLink
              line={2}
              variant={'body2'}
              pr={2}
              mb={1}
              sx={{ opacity: 0.8, textDecoration: 'none!important' }}
            >
              {description}
            </TextMaxLine>
          </NextLink>
        </Box>
      </Stack>
    </>
  );
};

export default memo(LinkedPosts);
