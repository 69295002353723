// ----------------------------------------------------------------------

const Routes = {
  page404: '/404',
  page500: '/500',
  blog: '/aktuelt',
  services: '/elektriker-tjenester',
  offers: '/kampanjer',
  about: '/om-oss',
  jobs: '/om-oss/ledige-stillinger',
  contact: '/kontakt',
  form: '/form',
  privacy: '/personvern',
  home: '/',
  projects: '/prosjekter',
  project: (slug: string) => `/prosjekter/${slug}`,
  post: (slug: string) => `/aktuelt/${slug}`,
  article: (slug: string) => `/aktuelt/${slug}`,
  service: (slug: string) => `/elektriker-tjenester/${slug}`,
  job: (slug: string) => `/om-oss/ledige-stillinger/${slug}`,
  offer: (slug: string) => `/kampanjer/${slug}`,
  page: (slug: string) => `/${slug}`,
};

export default Routes;
