import { Box, Theme, useTheme } from '@mui/material';
import { Image as SanityImageProps } from '@sanity/types';
import { newImageProps } from '@utils/image';
import staticLoader from '@utils/staticLoader';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import React, { CSSProperties } from 'react';

type Sizes = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

type ImageProps = {
  src: SanityImageProps | string;
  sizes?: Sizes;
  style?: CSSProperties;
  aspectRatio?: string;
  staticImage?: boolean;
} & Omit<NextImageProps, 'src' | 'sizes'>;

const Image: React.FC<ImageProps> = ({
  src,
  sizes,
  width,
  height,
  alt,
  style,
  aspectRatio,
  staticImage = false,
  priority = false,
}) => {
  const theme = useTheme();

  const loader = staticImage ? staticLoader : newImageProps(src).loader;
  const source = staticImage ? (src as string) : newImageProps(src).src;

  const ImageComponent: React.FC = () => (
    <NextImage
      alt={alt}
      style={{ objectFit: 'cover', ...style }}
      priority={priority}
      sizes={sizes && parseSizes(sizes, theme)}
      fill={!width}
      width={width}
      height={height}
      src={source}
      loader={loader}
    />
  );

  return aspectRatio ? (
    <Box sx={{ position: 'relative', aspectRatio: aspectRatio }}>
      <ImageComponent />
    </Box>
  ) : (
    <ImageComponent />
  );
};

const parseSizes = (sizes: Sizes, theme: Theme) => {
  const { xs, sm, md, lg, xl } = sizes;
  const breakpoints = theme.breakpoints.values;
  const p = (p: number) => (p / 12) * 100;

  return (
    (xl ? `(min-width: ${breakpoints.xl}px) ${p(xl)}vw, ` : '') +
    (lg ? `(min-width: ${breakpoints.lg}px) ${p(lg)}vw, ` : '') +
    (md ? `(min-width: ${breakpoints.md}px) ${p(md)}vw, ` : '') +
    (sm ? `(min-width: ${breakpoints.sm}px) ${p(sm)}vw, ` : '') +
    (xs ? `(min-width: ${breakpoints.xs}px) ${p(xs)}vw, ` : '')
  );
};

export default Image;
