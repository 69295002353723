import type { SanityClient } from '@sanity/client';
import { createClient } from '@sanity/client';

export const projectId = process.env.SANITY_STUDIO_API_PROJECT_ID || 'ldt10cgh';
export const dataset = process.env.SANITY_STUDIO_API_DATASET || 'production';
export const apiVersion = new Date().toJSON().slice(0, 10);

export const client = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn: false,
});

export function getClient(previewToken?: string): SanityClient {
  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn: false,
  });

  return previewToken
    ? client.withConfig({
        token: previewToken,
        useCdn: false,
        ignoreBrowserTokenWarning: true,
        perspective: 'previewDrafts',
      })
    : client;
}
