type StaticLoaderProps = {
  src: string;
  width?: number;
  quality?: number;
};

const staticLoader = ({ src, width, quality }: StaticLoaderProps) => {
  width;
  quality;
  return `${src}`; // REPLACE WITH YOUR IMAGE DIRECTORY
};

export default staticLoader;
