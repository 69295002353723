// TODO: TS this file

import { SanityImageCrop, SanityImageHotspot } from '@sanity/image-url/lib/types/types';
import { useNextSanityImage } from 'next-sanity-image';
import Image from 'next/image';
import { client } from 'src/lib/sanity.client';

const BREAKPOINTS = [640, 768, 1024, 1280, 1536]; // px

export const findLastNonNullValue = (items: string[], currentIndex: number) => {
  const sliced = items.slice(0, currentIndex);

  return sliced.filter((val) => val !== null).pop();
};

// const generateSrcSet = (
//   urlBuilder: ImageUrlBuilder,
//   breakpoints: number[],
//   { quality }: { quality: number }
// ) => {
//   return breakpoints
//     .map((width) => {
//       return `${urlBuilder.width(width).auto('format').quality(quality)} ${width}w`;
//     })
//     .join(', ');
// };

// Generate srcset sizes based off breakpoints
const generateSizes = (breakpoints: number[], sizes: string | undefined | string[]) => {
  if (!sizes) {
    return undefined;
  }

  if (typeof sizes === 'string') {
    return sizes;
  }

  if (sizes.length === 1 && sizes[0] !== null) {
    return sizes[0];
  }

  return sizes
    .map((val, i) => {
      if (i === sizes.length - 1) {
        return sizes[i];
      }

      let current = val;
      if (val === null) {
        current = findLastNonNullValue(sizes, i) || current;
      }

      return `(max-width: ${breakpoints?.[i]}px) ${current}`;
    })
    .join(', ');
};

/**
 * A simple image component that wraps around `@sanity/image-url`
 */
// alt={image?.altText}
// crop={image?.crop}
// dataset={dataset}
// hotspot={image?.hotspot}
// layout="fill"
// objectFit={isFilled ? 'cover' : 'contain'}
// objectPosition={'top'}
// projectId={projectId}
// sizes="100vw"
// src={image?.asset?._ref}

export default function SanityImage(
  props: {
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
    options?: { blur?: number };
    projectId: string;
    dataset: string;
    quality?: number;
    src?: string;
    alt?: string;
    height?: number;
    width?: number;
    srcSet?: string;
    objectFit?: any;
  } & Omit<React.ComponentProps<typeof Image>, 'alt' | 'src' | 'height' | 'width' | ' objectFit'>
) {
  const {
    // blurDataURL,
    crop,
    dataset,
    height,
    hotspot,
    layout,
    objectFit,
    options,
    projectId,
    quality = 80,
    sizes,
    src,
    width,
    ...rest
  } = props;

  const imageProps = useNextSanityImage(client, src || '', {
    imageBuilder: (imageUrlBuilder, { width: builderWidth }) => {
      return imageUrlBuilder
        .width(builderWidth || width || 0)
        .image({ _ref: src, crop, hotspot })
        .auto('format')
        .quality(quality);
    },
  });

  if (!dataset) {
    throw new Error('SanityImage is missing required "dataset" property.');
  }
  if (!projectId) {
    throw new Error('SanityImage is missing required "projectId" property.');
  }
  if (!src) {
    return null;
  }

  // Strip out blacklisted props
  delete rest?.['decoding'];
  delete rest?.['ref'];
  delete rest?.['srcSet'];
  delete rest?.['style'];

  // const urlBuilder = imageUrlBuilder({ projectId, dataset })
  //   .image({
  //     _ref: src,
  //     crop,
  //     hotspot,
  //   })
  //   .auto('format');

  // Generate srcset + sizes
  const srcSetSizes = generateSizes(BREAKPOINTS, sizes);
  // const srcSet = generateSrcSet(urlBuilder, BREAKPOINTS, { quality });

  // Determine image aspect ratio (factoring in any potential crop)
  let aspectRatio;
  if (height && width) {
    const multiplierWidth = 1 - (crop?.left || 0) - (crop?.right || 0);
    const multiplierHeight = 1 - (crop?.bottom || 0) - (crop?.top || 0);
    aspectRatio = (width * multiplierWidth) / (height * multiplierHeight);
  }

  // let urlDefault = urlBuilder;

  // Apply props
  /*
  if (height) {
    url = url.height(options.height);
  }
  if (width) {
    url = url.width(options.width);
  }
  */

  // TODO: check for valid range
  // if (options?.blur) {
  //   urlDefault = urlDefault.blur(options.blur);
  // }

  // const urlSrc = urlDefault.url() || '';

  return (
    <Image
      {...rest}
      {...imageProps}
      decoding="async"
      alt={rest.alt || ''}
      // src={blurDataURL}
      layout={layout}
      sizes={srcSetSizes}
      width={width}
      height={height}
      // src={urlSrc}
      // srcSet={srcSet as any}
      style={{
        overflow: 'hidden',
        ...(layout === 'fill' && {
          bottom: 0,
          height: '100%',
          left: 0,
          objectFit,
          position: 'absolute',
          right: 0,
          top: 0,
          width: '100%',
        }),
        ...(layout === 'responsive' && {
          aspectRatio,
          width: '100%',
        }),
      }}
    />
  );
}
